import { AgGridReact } from "ag-grid-react";
import { API } from "aws-amplify";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import "ag-grid-enterprise";
import { xouraTheme } from "../layouts/Themes";

const UserGrid = ({ filter, invitedUsers }) => {
  const gridRef = useRef<AgGridReact>(null);

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState([
    {
      headerName: "User ID",
      field: "id",
      filter: "agTextColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: false,
    },
    {
      headerName: "First Name",
      field: "firstName",
      filter: "agTextColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: false,
    },
    {
      headerName: "Last Name",
      field: "lastName",
      filter: "agTextColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: false,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  useEffect(() => {
    if (invitedUsers) {
      invitedUsers.forEach((user) => {
        const rowData: any[] = [];
        gridRef.current?.api.forEachNode(function (node) {
          rowData.push(node.data);
        });
        if (rowData.some((e) => e.id === user.id)) {
          gridRef.current?.api.applyTransaction({ update: [user] });
        } else {
          gridRef.current?.api.applyTransaction({ add: [user] });
        }
      });
    }
  }, [invitedUsers]);

  const onGridReady = useCallback(async () => {
    let data = await API.get("SecurityApi", "/api/auth/users", {});
    gridRef.current?.api.setGridOption("rowData", data);
  }, []);

  const sideBar = {
    defaultToolPanel: "",
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);

  return (
    <AgGridReact
      animateRows={true}
      autoGroupColumnDef={autoGroupColumnDef}
      cacheQuickFilter={true}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      groupDisplayType={"singleColumn"}
      includeHiddenColumnsInQuickFilter={true}
      onFirstDataRendered={onFirstDataRendered}
      onGridReady={onGridReady}
      quickFilterText={filter}
      ref={gridRef}
      rowGroupPanelShow={"always"}
      rowSelection={"single"}
      sideBar={sideBar}
      suppressCellFocus={true}
      theme={xouraTheme}
    ></AgGridReact>
  );
};

export default UserGrid;
