/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @interface TermItem
 */
export interface TermItem {
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    buName: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    lcId: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    lcName: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    ocId: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    ocName: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    spName: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    teBegin: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    teDocRef: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    teDocType: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    teEnd: string;
    /**
     * 
     * @type {boolean}
     * @memberof TermItem
     */
    teIsClosed: boolean;
    /**
     * 
     * @type {number}
     * @memberof TermItem
     */
    teSort: number;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    teStatus: string;
    /**
     * 
     * @type {string}
     * @memberof TermItem
     */
    teType: string;
}


/**
 * Check if a given object implements the TermItem interface.
 */
export function instanceOfTermItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "lcId" in value;
    isInstance = isInstance && "lcName" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    isInstance = isInstance && "teBegin" in value;
    isInstance = isInstance && "teDocRef" in value;
    isInstance = isInstance && "teDocType" in value;
    isInstance = isInstance && "teEnd" in value;
    isInstance = isInstance && "teIsClosed" in value;
    isInstance = isInstance && "teSort" in value;
    isInstance = isInstance && "teStatus" in value;
    isInstance = isInstance && "teType" in value;
    return isInstance;
}

export function TermItemFromJSON(json: any): TermItem {
    return TermItemFromJSONTyped(json, false);
}

export function TermItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buId': json['buId'],
        'buName': json['buName'],
        'entity': json['entity'],
        'lcId': json['lcId'],
        'lcName': json['lcName'],
        'ocId': json['ocId'],
        'ocName': json['ocName'],
        'spId': json['spId'],
        'spName': json['spName'],
        'teBegin': json['teBegin'],
        'teDocRef': json['teDocRef'],
        'teDocType': json['teDocType'],
        'teEnd': json['teEnd'],
        'teIsClosed': json['teIsClosed'],
        'teSort': json['teSort'],
        'teStatus': json['teStatus'],
        'teType': json['teType'],
    };
}

export function TermItemToJSON(value?: TermItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'blId': value.blId,
        'blName': value.blName,
        'buId': value.buId,
        'buName': value.buName,
        'entity': value.entity,
        'lcId': value.lcId,
        'lcName': value.lcName,
        'ocId': value.ocId,
        'ocName': value.ocName,
        'spId': value.spId,
        'spName': value.spName,
        'teBegin': value.teBegin,
        'teDocRef': value.teDocRef,
        'teDocType': value.teDocType,
        'teEnd': value.teEnd,
        'teIsClosed': value.teIsClosed,
        'teSort': value.teSort,
        'teStatus': value.teStatus,
        'teType': value.teType,
    };
}

