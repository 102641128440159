import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { AppContext } from "./AppContext";
import "./App.css";

// layouts
import AnonLayout from "./layouts/AnonLayout";
import AuthLayout from "./layouts/AuthLayout";
import RootLayout from "./layouts/RootLayout";

// pages
import ApiExplorer from "./pages/ApiExplorer";
import Dashboard from "./pages/Dashboard";
import Landing from "./pages/Landing";
import Occupancy from "./pages/Occupancy/Occupancy";
import OccupancyDetail from "./pages/Occupancy/OccupancyDetail";
import SignIn from "./pages/SignIn";
import Users, { userInvite } from "./pages/Users";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="" element={<AnonLayout />}>
        <Route index element={<Landing />} />
        <Route path="signin" element={<SignIn />} />
      </Route>
      <Route path="" element={<AuthLayout />}>
        <Route path="dash" element={<Dashboard />} />
        <Route path="occupancy">
          <Route index element={<Occupancy />} />
          <Route path=":id" element={<OccupancyDetail />} />
        </Route>
        <Route path="users">
          <Route index element={<Users />} action={userInvite} />
        </Route>
        <Route path="api" element={<ApiExplorer />} />
      </Route>
    </Route>,
  ),
);

export default function App() {
  return (
    <AppContext.Provider value={null}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}
