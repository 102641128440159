/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { ChargeItem } from './ChargeItem';
import {
    ChargeItemFromJSON,
    ChargeItemFromJSONTyped,
    ChargeItemToJSON,
    instanceOfChargeItem,
} from './ChargeItem';

/**
 * 
 * @export
 * @interface GetChargeItemsResponseContent
 */
export interface GetChargeItemsResponseContent {
    /**
     * 
     * @type {Array<ChargeItem>}
     * @memberof GetChargeItemsResponseContent
     */
    items?: Array<ChargeItem>;
}


/**
 * Check if a given object implements the GetChargeItemsResponseContent interface.
 */
export function instanceOfGetChargeItemsResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetChargeItemsResponseContentFromJSON(json: any): GetChargeItemsResponseContent {
    return GetChargeItemsResponseContentFromJSONTyped(json, false);
}

export function GetChargeItemsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChargeItemsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ChargeItemFromJSON)),
    };
}

export function GetChargeItemsResponseContentToJSON(value?: GetChargeItemsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ChargeItemToJSON)),
    };
}

