/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { OccupancyItem } from './OccupancyItem';
import {
    OccupancyItemFromJSON,
    OccupancyItemFromJSONTyped,
    OccupancyItemToJSON,
    instanceOfOccupancyItem,
} from './OccupancyItem';

/**
 * 
 * @export
 * @interface GetOccupancyItemsResponseContent
 */
export interface GetOccupancyItemsResponseContent {
    /**
     * 
     * @type {Array<OccupancyItem>}
     * @memberof GetOccupancyItemsResponseContent
     */
    items?: Array<OccupancyItem>;
}


/**
 * Check if a given object implements the GetOccupancyItemsResponseContent interface.
 */
export function instanceOfGetOccupancyItemsResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetOccupancyItemsResponseContentFromJSON(json: any): GetOccupancyItemsResponseContent {
    return GetOccupancyItemsResponseContentFromJSONTyped(json, false);
}

export function GetOccupancyItemsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOccupancyItemsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(OccupancyItemFromJSON)),
    };
}

export function GetOccupancyItemsResponseContentToJSON(value?: GetOccupancyItemsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(OccupancyItemToJSON)),
    };
}

