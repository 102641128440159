import { Navbar, Container } from "react-bootstrap";

export default function AnonFooter() {
  return (
    <Navbar expand="md">
      <Container fluid>
        <Navbar.Collapse>
          <Navbar.Text>
            <span>
              {process.env.REACT_APP_ENVIRONMENT} :{" "}
              {process.env.REACT_APP_VERSION}
            </span>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
