// Import models
import type {
  BadRequestErrorResponseContent,
  ForbiddenErrorResponseContent,
  GetAlertItemResponseContent,
  GetAlertItemsResponseContent,
  GetBillingItemResponseContent,
  GetBillingItemsResponseContent,
  GetChargeItemResponseContent,
  GetChargeItemsResponseContent,
  GetOccupancyItemResponseContent,
  GetOccupancyItemsResponseContent,
  GetOrganisationsResponseContent,
  GetReviewItemResponseContent,
  GetReviewItemsResponseContent,
  GetSalesItemResponseContent,
  GetSalesItemsResponseContent,
  GetTermItemResponseContent,
  GetTermItemsResponseContent,
  GetUserDataItemResponseContent,
  GetUserDataItemsResponseContent,
  InternalFailureErrorResponseContent,
  NotFoundErrorResponseContent,
  SignInRequestContent,
  SignUpRequestContent,
  UnauthorizedErrorResponseContent,
  XeroWebhookRequestContent,
} from '../models';
// Import request parameter interfaces
import {
    GetAlertItemRequest,
    GetAlertItemsRequest,
    GetBillingItemRequest,
    GetBillingItemsRequest,
    GetChargeItemRequest,
    GetChargeItemsRequest,
    GetOccupancyItemRequest,
    GetOccupancyItemsRequest,
    GetReviewItemRequest,
    GetReviewItemsRequest,
    GetSalesItemRequest,
    GetSalesItemsRequest,
    GetTermItemRequest,
    GetTermItemsRequest,
    GetUserDataItemRequest,
    GetUserDataItemsRequest,
    SignInRequest,
    SignUpRequest,
    XeroWebhookRequest,
} from '..';

import { ResponseError } from '../runtime';
import { DefaultApi } from './DefaultApi';
import { DefaultApiDefaultContext } from "./DefaultApiClientProvider";

import {
    useQuery,
    UseQueryResult,
    UseQueryOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { createContext, useContext } from "react";

/**
 * Context for the API client used by the hooks.
 */
export const DefaultApiClientContext = createContext<DefaultApi | undefined>(undefined);

const NO_API_ERROR = new Error(`DefaultApi client missing. Please ensure you have instantiated the DefaultApiClientProvider with a client instance.`);

/**
 * useQuery hook for the GetAlertItem operation
 */
export const useGetAlertItem = <TError = ResponseError>(
    params: GetAlertItemRequest,
    options?: Omit<UseQueryOptions<GetAlertItemResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetAlertItemResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getAlertItem", params], () => api.getAlertItem(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetAlertItems operation
 */
export const useGetAlertItems = <TError = ResponseError>(
    params: GetAlertItemsRequest,
    options?: Omit<UseQueryOptions<GetAlertItemsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetAlertItemsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getAlertItems", params], () => api.getAlertItems(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetBillingItem operation
 */
export const useGetBillingItem = <TError = ResponseError>(
    params: GetBillingItemRequest,
    options?: Omit<UseQueryOptions<GetBillingItemResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetBillingItemResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getBillingItem", params], () => api.getBillingItem(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetBillingItems operation
 */
export const useGetBillingItems = <TError = ResponseError>(
    params: GetBillingItemsRequest,
    options?: Omit<UseQueryOptions<GetBillingItemsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetBillingItemsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getBillingItems", params], () => api.getBillingItems(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetChargeItem operation
 */
export const useGetChargeItem = <TError = ResponseError>(
    params: GetChargeItemRequest,
    options?: Omit<UseQueryOptions<GetChargeItemResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetChargeItemResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getChargeItem", params], () => api.getChargeItem(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetChargeItems operation
 */
export const useGetChargeItems = <TError = ResponseError>(
    params: GetChargeItemsRequest,
    options?: Omit<UseQueryOptions<GetChargeItemsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetChargeItemsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getChargeItems", params], () => api.getChargeItems(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetOccupancyItem operation
 */
export const useGetOccupancyItem = <TError = ResponseError>(
    params: GetOccupancyItemRequest,
    options?: Omit<UseQueryOptions<GetOccupancyItemResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetOccupancyItemResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getOccupancyItem", params], () => api.getOccupancyItem(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetOccupancyItems operation
 */
export const useGetOccupancyItems = <TError = ResponseError>(
    params: GetOccupancyItemsRequest,
    options?: Omit<UseQueryOptions<GetOccupancyItemsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetOccupancyItemsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getOccupancyItems", params], () => api.getOccupancyItems(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetOrganisations operation
 */
export const useGetOrganisations = <TError = ResponseError>(
    options?: Omit<UseQueryOptions<GetOrganisationsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetOrganisationsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getOrganisations"], () => api.getOrganisations(), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetReviewItem operation
 */
export const useGetReviewItem = <TError = ResponseError>(
    params: GetReviewItemRequest,
    options?: Omit<UseQueryOptions<GetReviewItemResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetReviewItemResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getReviewItem", params], () => api.getReviewItem(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetReviewItems operation
 */
export const useGetReviewItems = <TError = ResponseError>(
    params: GetReviewItemsRequest,
    options?: Omit<UseQueryOptions<GetReviewItemsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetReviewItemsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getReviewItems", params], () => api.getReviewItems(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetSalesItem operation
 */
export const useGetSalesItem = <TError = ResponseError>(
    params: GetSalesItemRequest,
    options?: Omit<UseQueryOptions<GetSalesItemResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetSalesItemResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getSalesItem", params], () => api.getSalesItem(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetSalesItems operation
 */
export const useGetSalesItems = <TError = ResponseError>(
    params: GetSalesItemsRequest,
    options?: Omit<UseQueryOptions<GetSalesItemsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetSalesItemsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getSalesItems", params], () => api.getSalesItems(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetTermItem operation
 */
export const useGetTermItem = <TError = ResponseError>(
    params: GetTermItemRequest,
    options?: Omit<UseQueryOptions<GetTermItemResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetTermItemResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getTermItem", params], () => api.getTermItem(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetTermItems operation
 */
export const useGetTermItems = <TError = ResponseError>(
    params: GetTermItemsRequest,
    options?: Omit<UseQueryOptions<GetTermItemsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetTermItemsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getTermItems", params], () => api.getTermItems(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetUserDataItem operation
 */
export const useGetUserDataItem = <TError = ResponseError>(
    params: GetUserDataItemRequest,
    options?: Omit<UseQueryOptions<GetUserDataItemResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetUserDataItemResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getUserDataItem", params], () => api.getUserDataItem(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useQuery hook for the GetUserDataItems operation
 */
export const useGetUserDataItems = <TError = ResponseError>(
    params: GetUserDataItemsRequest,
    options?: Omit<UseQueryOptions<GetUserDataItemsResponseContent, TError>, 'queryKey' | 'queryFn'>
): UseQueryResult<GetUserDataItemsResponseContent, TError> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useQuery(["getUserDataItems", params], () => api.getUserDataItems(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the SignIn operation
 */
export const useSignIn = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, SignInRequest>, 'mutationFn'>
): UseMutationResult<void, TError, SignInRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: SignInRequest) => api.signIn(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the SignUp operation
 */
export const useSignUp = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, SignUpRequest>, 'mutationFn'>
): UseMutationResult<void, TError, SignUpRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: SignUpRequest) => api.signUp(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

/**
 * useMutation hook for the XeroWebhook operation
 */
export const useXeroWebhook = <TError = ResponseError>(
    options?: Omit<UseMutationOptions<void, TError, XeroWebhookRequest>, 'mutationFn'>
): UseMutationResult<void, TError, XeroWebhookRequest> => {
    const api = useContext(DefaultApiClientContext);
    if (!api) {
        throw NO_API_ERROR;
    }
    return useMutation((params: XeroWebhookRequest) => api.xeroWebhook(params), {
        context: DefaultApiDefaultContext,
        ...options,
    });
};

