/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { UserDataItem } from './UserDataItem';
import {
    UserDataItemFromJSON,
    UserDataItemFromJSONTyped,
    UserDataItemToJSON,
    instanceOfUserDataItem,
} from './UserDataItem';

/**
 * 
 * @export
 * @interface GetUserDataItemResponseContent
 */
export interface GetUserDataItemResponseContent {
    /**
     * 
     * @type {UserDataItem}
     * @memberof GetUserDataItemResponseContent
     */
    item?: UserDataItem;
}


/**
 * Check if a given object implements the GetUserDataItemResponseContent interface.
 */
export function instanceOfGetUserDataItemResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetUserDataItemResponseContentFromJSON(json: any): GetUserDataItemResponseContent {
    return GetUserDataItemResponseContentFromJSONTyped(json, false);
}

export function GetUserDataItemResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserDataItemResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'item': !exists(json, 'item') ? undefined : UserDataItemFromJSON(json['item']),
    };
}

export function GetUserDataItemResponseContentToJSON(value?: GetUserDataItemResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'item': UserDataItemToJSON(value.item),
    };
}

