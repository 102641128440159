/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { XeroEvent } from './XeroEvent';
import {
    XeroEventFromJSON,
    XeroEventFromJSONTyped,
    XeroEventToJSON,
    instanceOfXeroEvent,
} from './XeroEvent';

/**
 * 
 * @export
 * @interface XeroWebhookRequestContent
 */
export interface XeroWebhookRequestContent {
    /**
     * 
     * @type {Array<XeroEvent>}
     * @memberof XeroWebhookRequestContent
     */
    events: Array<XeroEvent>;
    /**
     * 
     * @type {number}
     * @memberof XeroWebhookRequestContent
     */
    firstEventSequence: number;
    /**
     * 
     * @type {number}
     * @memberof XeroWebhookRequestContent
     */
    lastEventSequence: number;
    /**
     * 
     * @type {string}
     * @memberof XeroWebhookRequestContent
     */
    entropy: string;
}


/**
 * Check if a given object implements the XeroWebhookRequestContent interface.
 */
export function instanceOfXeroWebhookRequestContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "events" in value;
    isInstance = isInstance && "firstEventSequence" in value;
    isInstance = isInstance && "lastEventSequence" in value;
    isInstance = isInstance && "entropy" in value;
    return isInstance;
}

export function XeroWebhookRequestContentFromJSON(json: any): XeroWebhookRequestContent {
    return XeroWebhookRequestContentFromJSONTyped(json, false);
}

export function XeroWebhookRequestContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): XeroWebhookRequestContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'events': ((json['events'] as Array<any>).map(XeroEventFromJSON)),
        'firstEventSequence': json['firstEventSequence'],
        'lastEventSequence': json['lastEventSequence'],
        'entropy': json['entropy'],
    };
}

export function XeroWebhookRequestContentToJSON(value?: XeroWebhookRequestContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'events': ((value.events as Array<any>).map(XeroEventToJSON)),
        'firstEventSequence': value.firstEventSequence,
        'lastEventSequence': value.lastEventSequence,
        'entropy': value.entropy,
    };
}

