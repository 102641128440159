import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  useGetUserDataItems,
  UserDataItem,
} from "xoura-api-typescript-react-query-hooks";
import { xouraTheme } from "../../layouts/Themes";

const UserDataGrid = ({ occupancy, filter }) => {
  const gridRef = useRef<AgGridReact<UserDataItem>>(null);
  const getUserDataItems = useGetUserDataItems(
    { occupancyId: occupancy.id },
    { enabled: false },
  );

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState<
    (ColDef<UserDataItem, any> | ColGroupDef<UserDataItem>)[]
  >([
    {
      headerName: "Id",
      field: "id",
      hide: true,
    },
    {
      headerName: "User Type Name",
      field: "utName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Value",
      field: "uvName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Is Closed",
      field: "udIsClosed",
      hide: true,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<UserDataItem[]>();

  useEffect(() => {
    getUserDataItems
      .refetch()
      .then((response) => setRowData(response.data?.items))
      .catch((err) => console.error(err));
  }, [occupancy]);

  const sideBar = {
    defaultToolPanel: "",
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.setFilterModel({
      udIsClosed: {
        values: ["false"],
      },
    });
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <AgGridReact<UserDataItem>
      animateRows={true}
      autoGroupColumnDef={autoGroupColumnDef}
      cacheQuickFilter={true}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      groupDisplayType={"singleColumn"}
      includeHiddenColumnsInQuickFilter={true}
      onFirstDataRendered={onFirstDataRendered}
      quickFilterText={filter}
      ref={gridRef}
      rowData={rowData}
      rowGroupPanelShow={"always"}
      rowSelection={"single"}
      sideBar={sideBar}
      suppressCellFocus={true}
      theme={xouraTheme}
    ></AgGridReact>
  );
};

export default UserDataGrid;
