/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { ReviewItem } from './ReviewItem';
import {
    ReviewItemFromJSON,
    ReviewItemFromJSONTyped,
    ReviewItemToJSON,
    instanceOfReviewItem,
} from './ReviewItem';

/**
 * 
 * @export
 * @interface GetReviewItemResponseContent
 */
export interface GetReviewItemResponseContent {
    /**
     * 
     * @type {ReviewItem}
     * @memberof GetReviewItemResponseContent
     */
    item?: ReviewItem;
}


/**
 * Check if a given object implements the GetReviewItemResponseContent interface.
 */
export function instanceOfGetReviewItemResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetReviewItemResponseContentFromJSON(json: any): GetReviewItemResponseContent {
    return GetReviewItemResponseContentFromJSONTyped(json, false);
}

export function GetReviewItemResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReviewItemResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'item': !exists(json, 'item') ? undefined : ReviewItemFromJSON(json['item']),
    };
}

export function GetReviewItemResponseContentToJSON(value?: GetReviewItemResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'item': ReviewItemToJSON(value.item),
    };
}

