/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * An error due to the server understood the request but refused to process it
 * @export
 * @interface ForbiddenErrorResponseContent
 */
export interface ForbiddenErrorResponseContent {
    /**
     * Message with details about the error
     * @type {string}
     * @memberof ForbiddenErrorResponseContent
     */
    message: string;
}


/**
 * Check if a given object implements the ForbiddenErrorResponseContent interface.
 */
export function instanceOfForbiddenErrorResponseContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;
    return isInstance;
}

export function ForbiddenErrorResponseContentFromJSON(json: any): ForbiddenErrorResponseContent {
    return ForbiddenErrorResponseContentFromJSONTyped(json, false);
}

export function ForbiddenErrorResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForbiddenErrorResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'message': json['message'],
    };
}

export function ForbiddenErrorResponseContentToJSON(value?: ForbiddenErrorResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'message': value.message,
    };
}

