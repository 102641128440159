/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @interface AlertItem
 */
export interface AlertItem {
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    alBegin: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    alText: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    alType: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    buName: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    lcId: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    lcName: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    ocId: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    ocName: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof AlertItem
     */
    spName: string;
}


/**
 * Check if a given object implements the AlertItem interface.
 */
export function instanceOfAlertItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "alBegin" in value;
    isInstance = isInstance && "alText" in value;
    isInstance = isInstance && "alType" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "lcId" in value;
    isInstance = isInstance && "lcName" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    return isInstance;
}

export function AlertItemFromJSON(json: any): AlertItem {
    return AlertItemFromJSONTyped(json, false);
}

export function AlertItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'alBegin': json['alBegin'],
        'alText': json['alText'],
        'alType': json['alType'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buId': json['buId'],
        'buName': json['buName'],
        'entity': json['entity'],
        'lcId': json['lcId'],
        'lcName': json['lcName'],
        'ocId': json['ocId'],
        'ocName': json['ocName'],
        'spId': json['spId'],
        'spName': json['spName'],
    };
}

export function AlertItemToJSON(value?: AlertItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'alBegin': value.alBegin,
        'alText': value.alText,
        'alType': value.alType,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'blId': value.blId,
        'blName': value.blName,
        'buId': value.buId,
        'buName': value.buName,
        'entity': value.entity,
        'lcId': value.lcId,
        'lcName': value.lcName,
        'ocId': value.ocId,
        'ocName': value.ocName,
        'spId': value.spId,
        'spName': value.spName,
    };
}

