/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { OccupancyItem } from './OccupancyItem';
import {
    OccupancyItemFromJSON,
    OccupancyItemFromJSONTyped,
    OccupancyItemToJSON,
    instanceOfOccupancyItem,
} from './OccupancyItem';

/**
 * 
 * @export
 * @interface GetOccupancyItemResponseContent
 */
export interface GetOccupancyItemResponseContent {
    /**
     * 
     * @type {OccupancyItem}
     * @memberof GetOccupancyItemResponseContent
     */
    item?: OccupancyItem;
}


/**
 * Check if a given object implements the GetOccupancyItemResponseContent interface.
 */
export function instanceOfGetOccupancyItemResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetOccupancyItemResponseContentFromJSON(json: any): GetOccupancyItemResponseContent {
    return GetOccupancyItemResponseContentFromJSONTyped(json, false);
}

export function GetOccupancyItemResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOccupancyItemResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'item': !exists(json, 'item') ? undefined : OccupancyItemFromJSON(json['item']),
    };
}

export function GetOccupancyItemResponseContentToJSON(value?: GetOccupancyItemResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'item': OccupancyItemToJSON(value.item),
    };
}

