import { Container } from "react-bootstrap";
import { Outlet } from "react-router";

export default function RootLayout() {
  return (
    <Container fluid className="d-flex flex-column p-0">
      <Outlet />
    </Container>
  );
}
