import React from "react";
import { DefaultApiClientProvider } from "xoura-api-typescript-react-query-hooks";
import { useXouraApiClient } from "../../hooks/useTypeSafeApiClient";

/**
 * Sets up the Type Safe Api clients.
 */
const TypeSafeApiClientProvider: React.FC<any> = ({ children }) => {
  const xouraClient = useXouraApiClient();
  return (
    <DefaultApiClientProvider apiClient={xouraClient!}>
      {children}
    </DefaultApiClientProvider>
  );
};

export default TypeSafeApiClientProvider;
