/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @interface BillingItem
 */
export interface BillingItem {
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    acId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    acName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biBegin: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biEnd: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biStatus: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    biType: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    buName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    chId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    itId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    itName: string;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    liAmount: number;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    liTax: number;
    /**
     * 
     * @type {number}
     * @memberof BillingItem
     */
    liTotal: number;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    ocId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    ocName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof BillingItem
     */
    spName: string;
}


/**
 * Check if a given object implements the BillingItem interface.
 */
export function instanceOfBillingItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "acId" in value;
    isInstance = isInstance && "acName" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "biBegin" in value;
    isInstance = isInstance && "biEnd" in value;
    isInstance = isInstance && "biStatus" in value;
    isInstance = isInstance && "biType" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "chId" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "itId" in value;
    isInstance = isInstance && "itName" in value;
    isInstance = isInstance && "liAmount" in value;
    isInstance = isInstance && "liTax" in value;
    isInstance = isInstance && "liTotal" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    return isInstance;
}

export function BillingItemFromJSON(json: any): BillingItem {
    return BillingItemFromJSONTyped(json, false);
}

export function BillingItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'acId': json['acId'],
        'acName': json['acName'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'biBegin': json['biBegin'],
        'biEnd': json['biEnd'],
        'biStatus': json['biStatus'],
        'biType': json['biType'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buId': json['buId'],
        'buName': json['buName'],
        'chId': json['chId'],
        'entity': json['entity'],
        'itId': json['itId'],
        'itName': json['itName'],
        'liAmount': json['liAmount'],
        'liTax': json['liTax'],
        'liTotal': json['liTotal'],
        'ocId': json['ocId'],
        'ocName': json['ocName'],
        'spId': json['spId'],
        'spName': json['spName'],
    };
}

export function BillingItemToJSON(value?: BillingItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'acId': value.acId,
        'acName': value.acName,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'biBegin': value.biBegin,
        'biEnd': value.biEnd,
        'biStatus': value.biStatus,
        'biType': value.biType,
        'blId': value.blId,
        'blName': value.blName,
        'buId': value.buId,
        'buName': value.buName,
        'chId': value.chId,
        'entity': value.entity,
        'itId': value.itId,
        'itName': value.itName,
        'liAmount': value.liAmount,
        'liTax': value.liTax,
        'liTotal': value.liTotal,
        'ocId': value.ocId,
        'ocName': value.ocName,
        'spId': value.spId,
        'spName': value.spName,
    };
}

