/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { SalesItem } from './SalesItem';
import {
    SalesItemFromJSON,
    SalesItemFromJSONTyped,
    SalesItemToJSON,
    instanceOfSalesItem,
} from './SalesItem';

/**
 * 
 * @export
 * @interface GetSalesItemsResponseContent
 */
export interface GetSalesItemsResponseContent {
    /**
     * 
     * @type {Array<SalesItem>}
     * @memberof GetSalesItemsResponseContent
     */
    items?: Array<SalesItem>;
}


/**
 * Check if a given object implements the GetSalesItemsResponseContent interface.
 */
export function instanceOfGetSalesItemsResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetSalesItemsResponseContentFromJSON(json: any): GetSalesItemsResponseContent {
    return GetSalesItemsResponseContentFromJSONTyped(json, false);
}

export function GetSalesItemsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalesItemsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(SalesItemFromJSON)),
    };
}

export function GetSalesItemsResponseContentToJSON(value?: GetSalesItemsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(SalesItemToJSON)),
    };
}

