/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @interface UserDataItem
 */
export interface UserDataItem {
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    buName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    lcId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    lcName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    ocId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    ocName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    spName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDataItem
     */
    udIsClosed: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    utId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    utName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    utType: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataItem
     */
    uvName: string;
}


/**
 * Check if a given object implements the UserDataItem interface.
 */
export function instanceOfUserDataItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "lcId" in value;
    isInstance = isInstance && "lcName" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    isInstance = isInstance && "udIsClosed" in value;
    isInstance = isInstance && "utId" in value;
    isInstance = isInstance && "utName" in value;
    isInstance = isInstance && "utType" in value;
    isInstance = isInstance && "uvName" in value;
    return isInstance;
}

export function UserDataItemFromJSON(json: any): UserDataItem {
    return UserDataItemFromJSONTyped(json, false);
}

export function UserDataItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDataItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buId': json['buId'],
        'buName': json['buName'],
        'entity': json['entity'],
        'lcId': json['lcId'],
        'lcName': json['lcName'],
        'ocId': json['ocId'],
        'ocName': json['ocName'],
        'spId': json['spId'],
        'spName': json['spName'],
        'udIsClosed': json['udIsClosed'],
        'utId': json['utId'],
        'utName': json['utName'],
        'utType': json['utType'],
        'uvName': json['uvName'],
    };
}

export function UserDataItemToJSON(value?: UserDataItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'blId': value.blId,
        'blName': value.blName,
        'buId': value.buId,
        'buName': value.buName,
        'entity': value.entity,
        'lcId': value.lcId,
        'lcName': value.lcName,
        'ocId': value.ocId,
        'ocName': value.ocName,
        'spId': value.spId,
        'spName': value.spName,
        'udIsClosed': value.udIsClosed,
        'utId': value.utId,
        'utName': value.utName,
        'utType': value.utType,
        'uvName': value.uvName,
    };
}

