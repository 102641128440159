/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { AlertItem } from './AlertItem';
import {
    AlertItemFromJSON,
    AlertItemFromJSONTyped,
    AlertItemToJSON,
    instanceOfAlertItem,
} from './AlertItem';

/**
 * 
 * @export
 * @interface GetAlertItemResponseContent
 */
export interface GetAlertItemResponseContent {
    /**
     * 
     * @type {AlertItem}
     * @memberof GetAlertItemResponseContent
     */
    item?: AlertItem;
}


/**
 * Check if a given object implements the GetAlertItemResponseContent interface.
 */
export function instanceOfGetAlertItemResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetAlertItemResponseContentFromJSON(json: any): GetAlertItemResponseContent {
    return GetAlertItemResponseContentFromJSONTyped(json, false);
}

export function GetAlertItemResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAlertItemResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'item': !exists(json, 'item') ? undefined : AlertItemFromJSON(json['item']),
    };
}

export function GetAlertItemResponseContentToJSON(value?: GetAlertItemResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'item': AlertItemToJSON(value.item),
    };
}

