import { Auth } from "aws-amplify";
import { useCallback } from "react";

export const useJwtClient = (disableCache: boolean) => {
  return useCallback(
    async (input: any, init: any) => {
      const jwt = (await Auth.currentSession()).getAccessToken().getJwtToken();
      init.headers.Authorization = `Bearer ${jwt}`;
      return fetch(input, init);
    },
    [disableCache],
  );
};
