import { themeQuartz } from "ag-grid-enterprise";

export const xouraTheme = themeQuartz.withParams({
  borderRadius: "2px",
  browserColorScheme: "light",
  fontSize: "12px",
  fontFamily: {
    googleFont: "Roboto",
  },
  headerFontSize: "12px",
  headerFontWeight: 400,
  iconSize: "14px",
  spacing: "4px",
  wrapperBorderRadius: "4px",
});
