/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { Organisation } from './Organisation';
import {
    OrganisationFromJSON,
    OrganisationFromJSONTyped,
    OrganisationToJSON,
    instanceOfOrganisation,
} from './Organisation';

/**
 * 
 * @export
 * @interface GetOrganisationsResponseContent
 */
export interface GetOrganisationsResponseContent {
    /**
     * 
     * @type {Array<Organisation>}
     * @memberof GetOrganisationsResponseContent
     */
    organisations?: Array<Organisation>;
}


/**
 * Check if a given object implements the GetOrganisationsResponseContent interface.
 */
export function instanceOfGetOrganisationsResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetOrganisationsResponseContentFromJSON(json: any): GetOrganisationsResponseContent {
    return GetOrganisationsResponseContentFromJSONTyped(json, false);
}

export function GetOrganisationsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrganisationsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'organisations': !exists(json, 'organisations') ? undefined : ((json['organisations'] as Array<any>).map(OrganisationFromJSON)),
    };
}

export function GetOrganisationsResponseContentToJSON(value?: GetOrganisationsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'organisations': value.organisations === undefined ? undefined : ((value.organisations as Array<any>).map(OrganisationToJSON)),
    };
}

