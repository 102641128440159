/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { SalesItem } from './SalesItem';
import {
    SalesItemFromJSON,
    SalesItemFromJSONTyped,
    SalesItemToJSON,
    instanceOfSalesItem,
} from './SalesItem';

/**
 * 
 * @export
 * @interface GetSalesItemResponseContent
 */
export interface GetSalesItemResponseContent {
    /**
     * 
     * @type {SalesItem}
     * @memberof GetSalesItemResponseContent
     */
    item?: SalesItem;
}


/**
 * Check if a given object implements the GetSalesItemResponseContent interface.
 */
export function instanceOfGetSalesItemResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetSalesItemResponseContentFromJSON(json: any): GetSalesItemResponseContent {
    return GetSalesItemResponseContentFromJSONTyped(json, false);
}

export function GetSalesItemResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSalesItemResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'item': !exists(json, 'item') ? undefined : SalesItemFromJSON(json['item']),
    };
}

export function GetSalesItemResponseContentToJSON(value?: GetSalesItemResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'item': SalesItemToJSON(value.item),
    };
}

