import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import NavAuth from "./NavAuth";

export default function AnonHeader() {
  return (
    <Navbar expand="md" bg="dark" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Brand
          href="/"
          className="d-flex align-items-center"
          style={{ fontSize: "2rem" }}
        >
          <FontAwesomeIcon icon={faHouse} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="xoura-expand" />
        <Navbar.Offcanvas
          id="xoura-expand"
          aria-labelledby="xoura-label-expand"
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="xoura-label-expand">Xoura</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Container fluid className="flex-grow-1 p-0">
              <NavAuth />
            </Container>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
