import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  TermItem,
  useGetTermItems,
} from "xoura-api-typescript-react-query-hooks";
import { xouraTheme } from "../../layouts/Themes";

const TermGrid = ({ occupancy, filter }) => {
  const gridRef = useRef<AgGridReact<TermItem>>(null);
  const getTermItems = useGetTermItems(
    { occupancyId: occupancy.id },
    { enabled: false },
  );

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState<
    (ColDef<TermItem, any> | ColGroupDef<TermItem>)[]
  >([
    {
      headerName: "Id",
      field: "id",
      hide: true,
    },
    {
      headerName: "Type",
      field: "teType",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Begin",
      field: "teBegin",
      type: "rightAligned",
    },
    {
      headerName: "End",
      field: "teEnd",
      type: "rightAligned",
    },
    {
      headerName: "Status",
      field: "teStatus",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Document Type",
      field: "teDocType",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Document Reference",
      field: "teDocRef",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Sequence",
      field: "teSort",
      type: "numericColumn",
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<TermItem[]>();

  useEffect(() => {
    getTermItems
      .refetch()
      .then((response) => setRowData(response.data?.items))
      .catch((err) => console.error(err));
  }, [occupancy]);

  const sideBar = {
    defaultToolPanel: "",
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.applyColumnState({
      state: [{ colId: "teBegin", sort: "desc", sortIndex: 0 }],
    });
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <AgGridReact<TermItem>
      animateRows={true}
      autoGroupColumnDef={autoGroupColumnDef}
      cacheQuickFilter={true}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      groupDisplayType={"singleColumn"}
      includeHiddenColumnsInQuickFilter={true}
      onFirstDataRendered={onFirstDataRendered}
      quickFilterText={filter}
      ref={gridRef}
      rowData={rowData}
      rowGroupPanelShow={"always"}
      rowSelection={"single"}
      sideBar={sideBar}
      suppressCellFocus={true}
      theme={xouraTheme}
    ></AgGridReact>
  );
};

export default TermGrid;
