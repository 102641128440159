import {
  ButtonDropdown,
  ContentLayout,
  Header,
} from "@cloudscape-design/components";
import { Auth } from "aws-amplify";
import { useCallback, useContext, useEffect, useState } from "react";
import { Alert, Row, Col, Container } from "react-bootstrap";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { RuntimeConfigContext } from "../../components/RuntimeContext";
// import { WebSocketExplorer } from "../../components/WebSocketApiExplorer";

/**
 * Component to render the home ApiExplorer.
 */
const ApiExplorer: React.FC = () => {
  const runtimeContext = useContext(RuntimeConfigContext);
  const [apiSpec, setApiSpec] = useState<{ [apiName: string]: any }>({});
  const [selectedApi, setSelectedApi] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();

  // Load the OpenAPI spec
  useEffect(() => {
    void (async () => {
      try {
        setApiSpec(
          Object.fromEntries([
            ...(
              await Promise.all(
                Object.keys(runtimeContext?.typeSafeApis ?? {}).map(
                  async (apiName) => [
                    apiName,
                    await fetch(`/${apiName}/api.json`).then((res) =>
                      res.json(),
                    ),
                  ],
                ),
              )
            ).map(([apiName, _apiSpec]) => [
              apiName,
              {
                ..._apiSpec,
                servers: [{ url: runtimeContext?.typeSafeApis[apiName] }],
              },
            ]),
            ...(
              await Promise.all(
                Object.keys(runtimeContext?.typeSafeWebSocketApis ?? {}).map(
                  async (apiName) => [
                    apiName,
                    await fetch(`/${apiName}/api.json`).then((res) =>
                      res.json(),
                    ),
                  ],
                ),
              )
            ).map(([apiName, _apiSpec]) => [
              apiName,
              {
                ..._apiSpec,
                url: runtimeContext?.typeSafeWebSocketApis[apiName],
                __websocket: true,
              },
            ]),
          ]),
        );
        setSelectedApi(
          Object.keys({
            ...runtimeContext?.typeSafeApis,
            ...runtimeContext?.typeSafeWebSocketApis,
          })[0],
        );
      } catch (e) {
        setError(
          "No OpenAPI definition detected. Ensure TypeSafeAPI(s) are passed in to Cloudscape Website construct.",
        );
      }
    })();
  }, []);

  const jwtInterceptor = useCallback(async (r: any) => {
    const jwt = (await Auth.currentSession()).getAccessToken().getJwtToken();
    r.headers.Authorization = `Bearer ${jwt}`;
    return r;
  }, []);

  return error ? (
    <Alert key="danger" variant="danger">
      {error}
    </Alert>
  ) : selectedApi ? (
    <Row className="flex-fill m-0 p-2">
      <Col className="d-flex flex-column m-0">
        <ContentLayout
          header={
            <Header
              variant="h1"
              actions={
                <ButtonDropdown
                  variant="primary"
                  onItemClick={(e) => setSelectedApi(e.detail.id!)}
                  items={[
                    ...(Object.keys(runtimeContext?.typeSafeApis).length > 0
                      ? [
                          {
                            text: "REST",
                            items: Object.keys(
                              runtimeContext?.typeSafeApis,
                            ).map((apiName) => ({
                              text: apiName,
                              id: apiName,
                              disabled: selectedApi === apiName,
                            })),
                          },
                        ]
                      : []),
                    ...(Object.keys(runtimeContext?.typeSafeWebSocketApis)
                      .length > 0
                      ? [
                          {
                            text: "WEBSOCKET",
                            items: Object.keys(
                              runtimeContext?.typeSafeWebSocketApis,
                            ).map((apiName) => ({
                              text: apiName,
                              id: apiName,
                              disabled: selectedApi === apiName,
                            })),
                          },
                        ]
                      : []),
                  ]}
                >
                  {selectedApi}
                </ButtonDropdown>
              }
            >
              API Explorer
            </Header>
          }
        >
          {apiSpec[selectedApi]?.__websocket ? (
            // <WebSocketExplorer
            //   selectedApi={selectedApi}
            //   spec={apiSpec[selectedApi]}
            // />
            <></>
          ) : (
            <Container>
              <SwaggerUI
                requestInterceptor={jwtInterceptor}
                spec={apiSpec[selectedApi]}
              />
            </Container>
          )}
        </ContentLayout>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default ApiExplorer;
