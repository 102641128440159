/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @enum {string}
 */
export type OccupancyStatus =
  'Closed' | 
  'Occupied' | 
  'Planned' | 
  'Vacated'




export function OccupancyStatusFromJSON(json: any): OccupancyStatus {
    return OccupancyStatusFromJSONTyped(json, false);
}

export function OccupancyStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OccupancyStatus {
    return json;
}

export function OccupancyStatusToJSON(value?: OccupancyStatus | null): any {
    return value;
}

