import { useAuthenticator } from "@aws-amplify/ui-react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import Loading from "../../components/Loading";
import OccupancyGrid from "../../components/Occupancy/OccupancyGrid";
import QuickFilter from "../../components/QuickFilter";

const Occupancy = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParams.get("filter") ?? "");

  return (
    <>
      {authStatus === "authenticated" ? (
        <Row className="flex-fill m-0 p-2">
          <Col className="d-flex flex-column m-0">
            <QuickFilter
              heading={"Occupancy"}
              filterValue={filter}
              filterHandler={setFilter}
            />
            <OccupancyGrid filter={filter} />
          </Col>
        </Row>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Occupancy;
