/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @interface XeroEvent
 */
export interface XeroEvent {
    /**
     * 
     * @type {string}
     * @memberof XeroEvent
     */
    resourceUrl: string;
    /**
     * 
     * @type {string}
     * @memberof XeroEvent
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroEvent
     */
    eventDateUtc: string;
    /**
     * 
     * @type {string}
     * @memberof XeroEvent
     */
    eventType: string;
    /**
     * 
     * @type {string}
     * @memberof XeroEvent
     */
    eventCategory: string;
    /**
     * 
     * @type {string}
     * @memberof XeroEvent
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroEvent
     */
    tenantType: string;
}


/**
 * Check if a given object implements the XeroEvent interface.
 */
export function instanceOfXeroEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resourceUrl" in value;
    isInstance = isInstance && "resourceId" in value;
    isInstance = isInstance && "eventDateUtc" in value;
    isInstance = isInstance && "eventType" in value;
    isInstance = isInstance && "eventCategory" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "tenantType" in value;
    return isInstance;
}

export function XeroEventFromJSON(json: any): XeroEvent {
    return XeroEventFromJSONTyped(json, false);
}

export function XeroEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): XeroEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'resourceUrl': json['resourceUrl'],
        'resourceId': json['resourceId'],
        'eventDateUtc': json['eventDateUtc'],
        'eventType': json['eventType'],
        'eventCategory': json['eventCategory'],
        'tenantId': json['tenantId'],
        'tenantType': json['tenantType'],
    };
}

export function XeroEventToJSON(value?: XeroEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'resourceUrl': value.resourceUrl,
        'resourceId': value.resourceId,
        'eventDateUtc': value.eventDateUtc,
        'eventType': value.eventType,
        'eventCategory': value.eventCategory,
        'tenantId': value.tenantId,
        'tenantType': value.tenantType,
    };
}

