/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { BillingItem } from './BillingItem';
import {
    BillingItemFromJSON,
    BillingItemFromJSONTyped,
    BillingItemToJSON,
    instanceOfBillingItem,
} from './BillingItem';

/**
 * 
 * @export
 * @interface GetBillingItemResponseContent
 */
export interface GetBillingItemResponseContent {
    /**
     * 
     * @type {BillingItem}
     * @memberof GetBillingItemResponseContent
     */
    item?: BillingItem;
}


/**
 * Check if a given object implements the GetBillingItemResponseContent interface.
 */
export function instanceOfGetBillingItemResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetBillingItemResponseContentFromJSON(json: any): GetBillingItemResponseContent {
    return GetBillingItemResponseContentFromJSONTyped(json, false);
}

export function GetBillingItemResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBillingItemResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'item': !exists(json, 'item') ? undefined : BillingItemFromJSON(json['item']),
    };
}

export function GetBillingItemResponseContentToJSON(value?: GetBillingItemResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'item': BillingItemToJSON(value.item),
    };
}

