import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { Cache } from "aws-amplify";
import React, { useCallback, useMemo, useState, useRef } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  OccupancyItem,
  useGetOccupancyItems,
} from "xoura-api-typescript-react-query-hooks";
import OccupancyToolPanel from "./OccupancyToolPanel";
import { xouraTheme } from "../../layouts/Themes";

const OccupancyGrid = ({ filter }) => {
  const gridRef = useRef<AgGridReact<OccupancyItem>>(null);
  const nav = useNavigate();
  const getOccupancyItems = useGetOccupancyItems({}, { enabled: false });

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState<
    (ColDef<OccupancyItem, any> | ColGroupDef<OccupancyItem>)[]
  >([
    {
      headerName: "Name",
      field: "ocName",
      filter: "agTextColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
    },
    {
      headerName: "Building",
      field: "buName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      rowGroup: false,
    },
    {
      headerName: "Level",
      field: "blName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      rowGroup: false,
    },
    {
      headerName: "Space",
      field: "spName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      rowGroup: false,
    },
    {
      headerName: "Contacts",
      children: [
        {
          headerName: "Billing",
          field: "bcName",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          hide: true,
        },
        {
          headerName: "Legal",
          field: "lcName",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
        },
      ],
    },
    {
      headerName: "Occupancy",
      children: [
        {
          headerName: "Begin",
          field: "ocBegin",
          type: "rightAligned",
        },
        {
          headerName: "End",
          field: "ocEnd",
          type: "rightAligned",
        },
      ],
    },
    {
      headerName: "Status",
      field: "ocStatus",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Type",
      field: "ocType",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<OccupancyItem[]>();

  const onGridReady = useCallback(async () => {
    let data: OccupancyItem[] = Cache.getItem("occ_grid_data");
    console.log("DATA", data);
    if (data === null) {
      getOccupancyItems
        .refetch()
        .then((r) => {
          const remote_data = r.data?.items!;
          Cache.setItem("occ_grid_data", remote_data);
          data = remote_data;
          setRowData(data);
        })
        .catch((err) => console.error(err));
    }
    setRowData(data);
  }, []);

  const refreshData = useCallback(() => {
    gridRef.current?.api.setGridOption("loading", true);
    getOccupancyItems
      .refetch()
      .then((r) => {
        const remote_data = r.data?.items!;
        Cache.setItem("occ_grid_data", remote_data);
        setRowData(remote_data);
        gridRef.current?.api.setGridOption("loading", false);
      })
      .catch((err) => console.error(err));
  }, []);

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (selectedRows?.length === 1) {
      nav("/occupancy/" + selectedRows[0].id);
    }
  };

  const getRowId = useMemo(() => {
    return (params) => params.data.id;
  }, []);

  const icons = useMemo(() => {
    return {
      "custom-stats": '<span class="ag-icon ag-icon-custom-stats"></span>',
    };
  }, []);

  const sideBar = useMemo(() => {
    return {
      defaultToolPanel: "",
      hiddenByDefault: false,
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "occupancy",
          labelDefault: "Occupancy",
          labelKey: "occupancy",
          iconKey: "custom-stats",
          toolPanel: OccupancyToolPanel,
          toolPanelParams: {
            // selectOccupancy: selectOccupancy,
          },
          width: 280,
        },
      ],
    };
  }, []);

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.applyColumnState({
      state: [
        { colId: "ocName", sort: "asc", sortIndex: 0 },
        { colId: "ocBegin", sort: "desc", sortIndex: 1 },
      ],
    });
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <>
      <Alert key="info" variant="info">
        <Row>
          <Col className="d-flex align-items-center">
            Select an occupancy to view details
          </Col>
          <Col className="d-flex justify-content-end">
            <Button onClick={refreshData} variant="outline-success" size="sm">
              Refresh Occupancy Data
            </Button>
          </Col>
        </Row>
      </Alert>
      <AgGridReact<OccupancyItem>
        animateRows={true}
        autoGroupColumnDef={autoGroupColumnDef}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        groupDefaultExpanded={3}
        groupDisplayType={"singleColumn"}
        icons={icons}
        includeHiddenColumnsInQuickFilter={true}
        loadThemeGoogleFonts
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        quickFilterText={filter}
        ref={gridRef}
        rowData={rowData}
        rowGroupPanelShow={"always"}
        rowSelection={"single"}
        sideBar={sideBar}
        suppressCellFocus={true}
        theme={xouraTheme}
      ></AgGridReact>
    </>
  );
};

export default OccupancyGrid;
