import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";

const Loading = () => {
  return (
    <Row className="flex-fill m-0 p-2">
      <Col className="d-flex flex-column m-0 justify-content-center">
        <h1 className="d-flex justify-content-center">
          <FontAwesomeIcon
            icon={faBuilding}
            flip="horizontal"
            beat
            size="2xl"
          />
        </h1>
      </Col>
    </Row>
  );
};

export default Loading;
