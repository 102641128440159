/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { UserDataItem } from './UserDataItem';
import {
    UserDataItemFromJSON,
    UserDataItemFromJSONTyped,
    UserDataItemToJSON,
    instanceOfUserDataItem,
} from './UserDataItem';

/**
 * 
 * @export
 * @interface GetUserDataItemsResponseContent
 */
export interface GetUserDataItemsResponseContent {
    /**
     * 
     * @type {Array<UserDataItem>}
     * @memberof GetUserDataItemsResponseContent
     */
    items?: Array<UserDataItem>;
}


/**
 * Check if a given object implements the GetUserDataItemsResponseContent interface.
 */
export function instanceOfGetUserDataItemsResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetUserDataItemsResponseContentFromJSON(json: any): GetUserDataItemsResponseContent {
    return GetUserDataItemsResponseContentFromJSONTyped(json, false);
}

export function GetUserDataItemsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserDataItemsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(UserDataItemFromJSON)),
    };
}

export function GetUserDataItemsResponseContentToJSON(value?: GetUserDataItemsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(UserDataItemToJSON)),
    };
}

