import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify, Auth } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { RuntimeConfigContext } from "../RuntimeContext";
/**
 * Sets up the runtimeConfig.
 *
 * This assumes a runtime-config.json file is present at '/'. In order for Auth to be set up automatically,
 * the runtime-config.json must have the following properties configured: [region, userPoolId, userPoolWebClientId, identityPoolId].
 */
const AmplifyAuthProvider: React.FC<any> = ({ children }) => {
  const runtimeConfig = useContext(RuntimeConfigContext);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (runtimeConfig) {
      Amplify.configure({
        aws_project_region: runtimeConfig?.region,
      });
      Amplify.Logger.LOG_LEVEL = "INFO";
      Auth.configure({
        region: runtimeConfig?.region,
        userPoolId: runtimeConfig?.userPoolId,
        userPoolWebClientId: runtimeConfig?.userPoolWebClientId,
        identityPoolId: runtimeConfig?.identityPoolId,
        mandatorySignIn: true,
        authenticationFlowType: "USER_SRP_AUTH",
        oauth: {
          domain: runtimeConfig?.oauth_domain,
          scope: [
            "phone",
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: runtimeConfig?.oauth_redirectSignIn,
          redirectSignOut: runtimeConfig?.oauth_redirectSignOut,
          responseType: "code",
        },
      });
    } else {
      setError("No runtime config detected");
    }
  }, [runtimeConfig]);

  return error ? (
    <h1>{error}</h1>
  ) : (
    <Authenticator.Provider>{children}</Authenticator.Provider>
  );
};

export default AmplifyAuthProvider;
