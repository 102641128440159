import { LicenseManager } from "ag-grid-enterprise";
import { Cache } from "aws-amplify";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import AmplifyAuthProvider from "./components/AmplifyAuthProvider";
import RuntimeConfigContextProvider from "./components/RuntimeContext";
import TypeSafeApiClientProvider from "./components/TypeSafeApiClientProvider";
import reportWebVitals from "./reportWebVitals";
import "./custom.scss";

LicenseManager.setLicenseKey(
  "[TRIAL]_this_AG_Grid_Enterprise_key_( AG-043118 )_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_purchasing_a_production_key_please_contact_( info@ag-grid.com )___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_( 31 July 2023 )____[v2]_MTY5MDc1ODAwMDAwMA==f7deb9985cb10bc1921d8a43ac3c1b44",
);

Cache.configure({
  storage: window.sessionStorage,
  capacityInBytes: 5242880,
  itemMaxSize: 500000,
});

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RuntimeConfigContextProvider>
      <AmplifyAuthProvider>
        <TypeSafeApiClientProvider>
          <App />
        </TypeSafeApiClientProvider>
      </AmplifyAuthProvider>
    </RuntimeConfigContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
