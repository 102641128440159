/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { AlertItem } from './AlertItem';
import {
    AlertItemFromJSON,
    AlertItemFromJSONTyped,
    AlertItemToJSON,
    instanceOfAlertItem,
} from './AlertItem';

/**
 * 
 * @export
 * @interface GetAlertItemsResponseContent
 */
export interface GetAlertItemsResponseContent {
    /**
     * 
     * @type {Array<AlertItem>}
     * @memberof GetAlertItemsResponseContent
     */
    items?: Array<AlertItem>;
}


/**
 * Check if a given object implements the GetAlertItemsResponseContent interface.
 */
export function instanceOfGetAlertItemsResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetAlertItemsResponseContentFromJSON(json: any): GetAlertItemsResponseContent {
    return GetAlertItemsResponseContentFromJSONTyped(json, false);
}

export function GetAlertItemsResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAlertItemsResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(AlertItemFromJSON)),
    };
}

export function GetAlertItemsResponseContentToJSON(value?: GetAlertItemsResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(AlertItemToJSON)),
    };
}

