/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { TermItem } from './TermItem';
import {
    TermItemFromJSON,
    TermItemFromJSONTyped,
    TermItemToJSON,
    instanceOfTermItem,
} from './TermItem';

/**
 * 
 * @export
 * @interface GetTermItemResponseContent
 */
export interface GetTermItemResponseContent {
    /**
     * 
     * @type {TermItem}
     * @memberof GetTermItemResponseContent
     */
    item?: TermItem;
}


/**
 * Check if a given object implements the GetTermItemResponseContent interface.
 */
export function instanceOfGetTermItemResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetTermItemResponseContentFromJSON(json: any): GetTermItemResponseContent {
    return GetTermItemResponseContentFromJSONTyped(json, false);
}

export function GetTermItemResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTermItemResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'item': !exists(json, 'item') ? undefined : TermItemFromJSON(json['item']),
    };
}

export function GetTermItemResponseContentToJSON(value?: GetTermItemResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'item': TermItemToJSON(value.item),
    };
}

