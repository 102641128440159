import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  SalesItem,
  useGetSalesItems,
} from "xoura-api-typescript-react-query-hooks";
import { xouraTheme } from "../../layouts/Themes";
import {
  AccountingFormatter,
  AccountingStyle,
  ValueFormatter,
} from "../Formatters";

const SalesGrid = ({ occupancy, filter }) => {
  const gridRef = useRef<AgGridReact<SalesItem>>(null);
  const getSalesItems = useGetSalesItems(
    { occupancyId: occupancy.id },
    { enabled: false },
  );

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState<
    (ColDef<SalesItem, any> | ColGroupDef<SalesItem>)[]
  >([
    {
      headerName: "Id",
      field: "id",
      hide: true,
    },
    {
      headerName: "Category",
      field: "scName",
      filter: "agSetColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "Group",
      field: "sgName",
      filter: "agSetColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "Type",
      field: "saType",
      filter: "agSetColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
      enablePivot: true,
    },
    {
      headerName: "Date",
      field: "saDate",
      type: "rightAligned",
      enablePivot: true,
    },
    {
      headerName: "Amount",
      field: "saAmount",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
      aggFunc: "sum",
    },
    {
      headerName: "Amount m2",
      field: "saAmountByArea",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
      aggFunc: "sum",
    },
    {
      headerName: "MAT",
      field: "saMat",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
      aggFunc: "sum",
    },
    {
      headerName: "MAT m2",
      field: "saMatByArea",
      filter: "agNumberColumnFilter",
      valueFormatter: AccountingFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
      aggFunc: "sum",
    },
    {
      headerName: "Area",
      field: "saArea",
      filter: "agNumberColumnFilter",
      valueFormatter: ValueFormatter,
      cellStyle: AccountingStyle,
      type: "numericColumn",
      aggFunc: "sum",
    },
    {
      headerName: "Status",
      field: "saStatus",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      enablePivot: true,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<SalesItem[]>();

  useEffect(() => {
    getSalesItems
      .refetch()
      .then((response) => setRowData(response.data?.items))
      .catch((err) => console.error(err));
  }, [occupancy]);

  const sideBar = {
    defaultToolPanel: "",
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.setFilterModel({
      saStatus: {
        values: ["Completed", "Requested", "Waiting"],
      },
    });
    gridRef.current?.api.applyColumnState({
      state: [{ colId: "saDate", sort: "desc", sortIndex: 0 }],
    });
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <AgGridReact<SalesItem>
      animateRows={true}
      autoGroupColumnDef={autoGroupColumnDef}
      cacheQuickFilter={true}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      groupDisplayType={"singleColumn"}
      includeHiddenColumnsInQuickFilter={true}
      onFirstDataRendered={onFirstDataRendered}
      quickFilterText={filter}
      ref={gridRef}
      rowData={rowData}
      rowGroupPanelShow={"always"}
      rowSelection={"single"}
      sideBar={sideBar}
      suppressCellFocus={true}
      theme={xouraTheme}
    ></AgGridReact>
  );
};

export default SalesGrid;
