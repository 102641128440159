import { Spinner } from "@cloudscape-design/components";
import React, { createContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

export interface RuntimeConfig {
  readonly region: string;
  readonly userPoolId: string;
  readonly userPoolWebClientId: string;
  readonly identityPoolId: string;
  readonly oauth_domain: string;
  readonly oauth_redirectSignIn: string;
  readonly oauth_redirectSignOut: string;
  readonly [additionalProps: string]: any;
}

/**
 * Context for storing the runtimeConfig.
 */
export const RuntimeConfigContext = createContext<RuntimeConfig | undefined>(
  undefined,
);

/**
 * Sets up the runtimeConfig.
 *
 * This assumes a runtime-config.json file is present at '/'. In order for Auth to be set up automatically,
 * the runtime-config.json must have the following properties configured: [region, userPoolId, userPoolWebClientId, identityPoolId].
 */
const RuntimeConfigContextProvider: React.FC<any> = ({ children }) => {
  const [runtimeConfig, setRuntimeConfig] = useState<
    RuntimeConfig | undefined
  >();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    fetch("/runtime-config.json")
      .then((response) => {
        return response.json();
      })
      .then((rc) => {
        if (
          rc.region &&
          rc.userPoolId &&
          rc.userPoolWebClientId &&
          rc.identityPoolId
        ) {
          setRuntimeConfig(rc as RuntimeConfig);
        } else {
          setError(
            "runtime-config.json should have region, userPoolId, userPoolWebClientId & identityPoolId.",
          );
        }
      })
      .catch(() => {
        setError("No runtime-config.json detected");
      });
  }, [setRuntimeConfig]);

  return error ? (
    <Alert key="danger" variant="danger">
      {error}
    </Alert>
  ) : runtimeConfig ? (
    <RuntimeConfigContext.Provider value={runtimeConfig}>
      {children}
    </RuntimeConfigContext.Provider>
  ) : (
    <Spinner></Spinner>
  );
};

export default RuntimeConfigContextProvider;
