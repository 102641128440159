/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @interface ReviewItem
 */
export interface ReviewItem {
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    buName: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    itId: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    itName: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    lcId: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    lcName: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    ocId: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    ocName: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    reBegin: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReviewItem
     */
    reIsClosed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    reStatus: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    reType: string;
    /**
     * 
     * @type {number}
     * @memberof ReviewItem
     */
    reValue: number;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewItem
     */
    spName: string;
}


/**
 * Check if a given object implements the ReviewItem interface.
 */
export function instanceOfReviewItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "itId" in value;
    isInstance = isInstance && "itName" in value;
    isInstance = isInstance && "lcId" in value;
    isInstance = isInstance && "lcName" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "reBegin" in value;
    isInstance = isInstance && "reIsClosed" in value;
    isInstance = isInstance && "reStatus" in value;
    isInstance = isInstance && "reType" in value;
    isInstance = isInstance && "reValue" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    return isInstance;
}

export function ReviewItemFromJSON(json: any): ReviewItem {
    return ReviewItemFromJSONTyped(json, false);
}

export function ReviewItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buId': json['buId'],
        'buName': json['buName'],
        'entity': json['entity'],
        'itId': json['itId'],
        'itName': json['itName'],
        'lcId': json['lcId'],
        'lcName': json['lcName'],
        'ocId': json['ocId'],
        'ocName': json['ocName'],
        'reBegin': json['reBegin'],
        'reIsClosed': json['reIsClosed'],
        'reStatus': json['reStatus'],
        'reType': json['reType'],
        'reValue': json['reValue'],
        'spId': json['spId'],
        'spName': json['spName'],
    };
}

export function ReviewItemToJSON(value?: ReviewItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'blId': value.blId,
        'blName': value.blName,
        'buId': value.buId,
        'buName': value.buName,
        'entity': value.entity,
        'itId': value.itId,
        'itName': value.itName,
        'lcId': value.lcId,
        'lcName': value.lcName,
        'ocId': value.ocId,
        'ocName': value.ocName,
        'reBegin': value.reBegin,
        'reIsClosed': value.reIsClosed,
        'reStatus': value.reStatus,
        'reType': value.reType,
        'reValue': value.reValue,
        'spId': value.spId,
        'spName': value.spName,
    };
}

