/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @enum {string}
 */
export type SalesType =
  'Reported' | 
  'Audited'




export function SalesTypeFromJSON(json: any): SalesType {
    return SalesTypeFromJSONTyped(json, false);
}

export function SalesTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesType {
    return json;
}

export function SalesTypeToJSON(value?: SalesType | null): any {
    return value;
}

