import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navbar, Container } from "react-bootstrap";

const Footer = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  return (
    <Navbar expand="md">
      <Container fluid>
        <Navbar.Collapse>
          <Navbar.Text>
            <span>
              {process.env.REACT_APP_ENVIRONMENT} :{" "}
              {process.env.REACT_APP_VERSION}
            </span>
          </Navbar.Text>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <span>{user ? user.attributes?.email : ""}</span>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Footer;
