import { Modal, Button, Row, Col, Form } from "react-bootstrap";

const ChargeForm = ({ charge, handleClose }) => {
  const dateFormatter = (params) => {
    if (params.value) return new Date(params.value).toLocaleDateString();
    return "";
  };

  const numericFormatter = (params) => {
    if (params.value) return params.value.toFixed(2);
    return "";
  };

  return (
    <>
      {charge ? (
        <Modal show={charge} onHide={handleClose} dialogClassName="modal-90w">
          <Modal.Header closeButton>
            <Modal.Title>{charge.bcName} : Charge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Row} className="mb-3" controlId="formChargeId">
                <Form.Label column sm="2">
                  Charge Id
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charge Id"
                    plaintext
                    readOnly
                    defaultValue={charge.id}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formChargeStatus"
              >
                <Form.Label column sm="2">
                  Charge Status
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charge Status"
                    plaintext
                    readOnly
                    defaultValue={charge.chStatus}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formChargeType">
                <Form.Label column sm="2">
                  Charge Type
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charge Type"
                    plaintext
                    readOnly
                    defaultValue={charge.chType}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formChargeAmount"
              >
                <Form.Label column sm="2">
                  Charge Amount
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charge Amount"
                    readOnly
                    defaultValue={numericFormatter({ value: charge.chAmount })}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formChargeBasis">
                <Form.Label column sm="2">
                  Charge Basis
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charge Basis"
                    readOnly
                    defaultValue={charge.chBasis}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formChargeFrequency"
              >
                <Form.Label column sm="2">
                  Charge Frequency
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charge Frequency"
                    readOnly
                    defaultValue={charge.chFrequency}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formChargeBegin">
                <Form.Label column sm="2">
                  Charge Begin
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charge Begin"
                    readOnly
                    defaultValue={dateFormatter({ value: charge.chBegin })}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formChargeEnd">
                <Form.Label column sm="2">
                  Charge End
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charge End"
                    readOnly
                    defaultValue={dateFormatter({ value: charge.chEnd })}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formChargedToDate"
              >
                <Form.Label column sm="2">
                  Charged To
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="sm"
                    placeholder="Charged To"
                    readOnly
                    defaultValue={dateFormatter({ value: charge.chChargedTo })}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChargeForm;
