import { useContext, useMemo } from "react";
import {
  DefaultApi as XouraApi,
  Configuration as XouraApiConfiguration,
} from "xoura-api-typescript-react-query-hooks";
import { useJwtClient } from "./useJwtClient";
import { RuntimeConfigContext } from "../components/RuntimeContext";

export const useXouraApiClient = () => {
  const runtimeConfig = useContext(RuntimeConfigContext);
  const client = useJwtClient(false);
  return useMemo(() => {
    return runtimeConfig?.typeSafeApis?.XouraApi
      ? new XouraApi(
          new XouraApiConfiguration({
            basePath: runtimeConfig.typeSafeApis.XouraApi,
            fetchApi: client,
          }),
        )
      : undefined;
  }, [client, runtimeConfig?.typeSafeApis?.XouraApi]);
};
