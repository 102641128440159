import {
  faPeopleGroup,
  faHouse,
  faUsers,
  faMapSigns,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import NavAuth from "./NavAuth";
import Selector from "./Organisation/Selector";

const Header = () => {
  return (
    <Navbar expand="md" bg="dark" data-bs-theme="dark">
      <Container fluid>
        <NavLink
          to={"dash"}
          className={"d-flex align-items-center navbar-brand"}
          style={{ fontSize: "2rem" }}
        >
          <FontAwesomeIcon icon={faHouse} />
        </NavLink>
        <Navbar.Toggle aria-controls="xoura-expand" />
        <Navbar.Offcanvas
          id="xoura-expand"
          aria-labelledby="xoura-label-expand"
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="xoura-label-expand">Xoura</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-grow-1 pe-3">
              <NavLink to={"occupancy"} className={"nav-link"}>
                <FontAwesomeIcon icon={faPeopleGroup} className="pe-1" />
                Occupancy
              </NavLink>
              <NavLink to={"users"} className={"nav-link"}>
                <FontAwesomeIcon icon={faUsers} className="pe-1" />
                Users
              </NavLink>
              <NavLink to={"api"} className={"nav-link"}>
                <FontAwesomeIcon icon={faMapSigns} className="pe-1" />
                Api
              </NavLink>
            </Nav>
            <hr />
            <Selector />
            <hr />
            <NavAuth />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
