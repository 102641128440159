import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function AuthLayout() {
  return (
    <>
      <Row className="m-0">
        <Col className="p-0">
          <Header />
        </Col>
      </Row>
      <Row className="flex-fill m-0">
        <Col className="d-flex p-0">
          <Outlet />
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="p-0">
          <Footer />
        </Col>
      </Row>
    </>
  );
}
