/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';

/**
 * 
 * @export
 * @enum {string}
 */
export type SalesStatus =
  'Completed' | 
  'MAT-Stub' | 
  'Requested' | 
  'Waiting'




export function SalesStatusFromJSON(json: any): SalesStatus {
    return SalesStatusFromJSONTyped(json, false);
}

export function SalesStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesStatus {
    return json;
}

export function SalesStatusToJSON(value?: SalesStatus | null): any {
    return value;
}

