import { faTreeCity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useCallback } from "react";
import { Row, Col, FormControl } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

const QuickFilter = ({ heading, filterValue, filterHandler }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const applyFilterValue = useCallback(
    (value) => {
      filterHandler(value);
      if (value) {
        searchParams.set("filter", value);
      } else {
        searchParams.delete("filter");
      }
      setSearchParams(searchParams);
    },
    [filterHandler, searchParams, setSearchParams],
  );

  useEffect(() => {
    applyFilterValue(filterValue);
  }, [filterValue, applyFilterValue]);

  const onInput = (e) => {
    applyFilterValue(e.target.value);
  };

  return (
    <Row className="m-0 p-2">
      <Col className="d-flex flex-column flex-grow-1 m-0 p-0">
        <h3>
          <FontAwesomeIcon icon={faTreeCity} /> {heading}
        </h3>
      </Col>
      <Col md={3} className="d-flex flex-column m-0 p-0">
        <FormControl
          type="search"
          placeholder="Quick filter ..."
          onInput={onInput}
          value={filterValue}
        />
      </Col>
    </Row>
  );
};

export default QuickFilter;
