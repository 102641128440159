import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import {
  AlertItem,
  useGetAlertItems,
} from "xoura-api-typescript-react-query-hooks";
import { xouraTheme } from "../../layouts/Themes";

const AlertGrid = ({ occupancy, filter }) => {
  const gridRef = useRef<AgGridReact<AlertItem>>(null);
  const getAlertItems = useGetAlertItems(
    { occupancyId: occupancy.id },
    { enabled: false },
  );

  const [defaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = useState<
    (ColDef<AlertItem, any> | ColGroupDef<AlertItem>)[]
  >([
    {
      headerName: "Alert Type",
      field: "alType",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Alert Date",
      field: "alBegin",
      type: "rightAligned",
    },
    {
      headerName: "Alert Description",
      field: "alText",
      filter: "agTextColumnFilter",
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<AlertItem[]>();

  useEffect(() => {
    getAlertItems
      .refetch()
      .then((response) => setRowData(response.data?.items))
      .catch((err) => console.error(err));
  }, [occupancy]);

  const sideBar = {
    defaultToolPanel: "",
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <AgGridReact<AlertItem>
      animateRows={true}
      autoGroupColumnDef={autoGroupColumnDef}
      cacheQuickFilter={true}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      groupDisplayType={"singleColumn"}
      includeHiddenColumnsInQuickFilter={true}
      onFirstDataRendered={onFirstDataRendered}
      quickFilterText={filter}
      ref={gridRef}
      rowData={rowData}
      rowGroupPanelShow={"always"}
      rowSelection={"single"}
      sideBar={sideBar}
      suppressCellFocus={true}
      theme={xouraTheme}
    ></AgGridReact>
  );
};

export default AlertGrid;
