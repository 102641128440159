import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Organisation,
  useGetOrganisations,
} from "xoura-api-typescript-react-query-hooks";

const Selector = () => {
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);
  const [title, setTitle] = useState<string>();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const nav = useNavigate();
  const getOrganisations = useGetOrganisations();

  async function selectOrganisation(id) {
    Auth.updateUserAttributes(user, { "custom:active_short_code": id })
      .then((_) => {
        nav("/dash");
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if (getOrganisations.isSuccess) {
      setOrganisations(getOrganisations.data.organisations!);
      if (authStatus === "authenticated" && user.attributes) {
        if (user.attributes["custom:active_short_code"]) {
          const shortCode = user.attributes["custom:active_short_code"];
          setTitle(
            organisations.find((o) => o.shortCode === shortCode)?.shortCode,
          );
        }
      }
    }
  }, [getOrganisations.isSuccess, organisations, authStatus, user]);

  return (
    <>
      {authStatus === "authenticated" ? (
        <Nav className="justify-content-end">
          <NavDropdown title={title} className="pe-3">
            {organisations.map((o) => (
              <NavDropdown.Item
                key={o.shortCode}
                onClick={() => selectOrganisation(o.shortCode)}
              >
                {o.shortCode}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
      ) : (
        <></>
      )}
    </>
  );
};

export default Selector;
