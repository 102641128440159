/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { SalesStatus } from './SalesStatus';
import {
    SalesStatusFromJSON,
    SalesStatusFromJSONTyped,
    SalesStatusToJSON,
} from './SalesStatus';
import type { SalesType } from './SalesType';
import {
    SalesTypeFromJSON,
    SalesTypeFromJSONTyped,
    SalesTypeToJSON,
} from './SalesType';

/**
 * 
 * @export
 * @interface SalesItem
 */
export interface SalesItem {
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    buName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    ko1Id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    ko1Key: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    lcId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    lcName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    ocId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    ocName: string;
    /**
     * 
     * @type {number}
     * @memberof SalesItem
     */
    saAmount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesItem
     */
    saAmountByArea: number;
    /**
     * 
     * @type {number}
     * @memberof SalesItem
     */
    saArea: number;
    /**
     * 
     * @type {number}
     * @memberof SalesItem
     */
    saMat: number;
    /**
     * 
     * @type {number}
     * @memberof SalesItem
     */
    saMatByArea: number;
    /**
     * 
     * @type {SalesStatus}
     * @memberof SalesItem
     */
    saStatus: SalesStatus;
    /**
     * 
     * @type {SalesType}
     * @memberof SalesItem
     */
    saType: SalesType;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    saDate: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    scId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    scName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    sgId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    sgName: string;
    /**
     * 
     * @type {number}
     * @memberof SalesItem
     */
    spArea: number;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof SalesItem
     */
    spName: string;
}


/**
 * Check if a given object implements the SalesItem interface.
 */
export function instanceOfSalesItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "ko1Id" in value;
    isInstance = isInstance && "ko1Key" in value;
    isInstance = isInstance && "lcId" in value;
    isInstance = isInstance && "lcName" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "saAmount" in value;
    isInstance = isInstance && "saAmountByArea" in value;
    isInstance = isInstance && "saArea" in value;
    isInstance = isInstance && "saMat" in value;
    isInstance = isInstance && "saMatByArea" in value;
    isInstance = isInstance && "saStatus" in value;
    isInstance = isInstance && "saType" in value;
    isInstance = isInstance && "saDate" in value;
    isInstance = isInstance && "scId" in value;
    isInstance = isInstance && "scName" in value;
    isInstance = isInstance && "sgId" in value;
    isInstance = isInstance && "sgName" in value;
    isInstance = isInstance && "spArea" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    return isInstance;
}

export function SalesItemFromJSON(json: any): SalesItem {
    return SalesItemFromJSONTyped(json, false);
}

export function SalesItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buId': json['buId'],
        'buName': json['buName'],
        'entity': json['entity'],
        'ko1Id': json['ko1Id'],
        'ko1Key': json['ko1Key'],
        'lcId': json['lcId'],
        'lcName': json['lcName'],
        'ocId': json['ocId'],
        'ocName': json['ocName'],
        'saAmount': json['saAmount'],
        'saAmountByArea': json['saAmountByArea'],
        'saArea': json['saArea'],
        'saMat': json['saMat'],
        'saMatByArea': json['saMatByArea'],
        'saStatus': SalesStatusFromJSON(json['saStatus']),
        'saType': SalesTypeFromJSON(json['saType']),
        'saDate': json['saDate'],
        'scId': json['scId'],
        'scName': json['scName'],
        'sgId': json['sgId'],
        'sgName': json['sgName'],
        'spArea': json['spArea'],
        'spId': json['spId'],
        'spName': json['spName'],
    };
}

export function SalesItemToJSON(value?: SalesItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'blId': value.blId,
        'blName': value.blName,
        'buId': value.buId,
        'buName': value.buName,
        'entity': value.entity,
        'ko1Id': value.ko1Id,
        'ko1Key': value.ko1Key,
        'lcId': value.lcId,
        'lcName': value.lcName,
        'ocId': value.ocId,
        'ocName': value.ocName,
        'saAmount': value.saAmount,
        'saAmountByArea': value.saAmountByArea,
        'saArea': value.saArea,
        'saMat': value.saMat,
        'saMatByArea': value.saMatByArea,
        'saStatus': SalesStatusToJSON(value.saStatus),
        'saType': SalesTypeToJSON(value.saType),
        'saDate': value.saDate,
        'scId': value.scId,
        'scName': value.scName,
        'sgId': value.sgId,
        'sgName': value.sgName,
        'spArea': value.spArea,
        'spId': value.spId,
        'spName': value.spName,
    };
}

