/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { ChargeItem } from './ChargeItem';
import {
    ChargeItemFromJSON,
    ChargeItemFromJSONTyped,
    ChargeItemToJSON,
    instanceOfChargeItem,
} from './ChargeItem';

/**
 * 
 * @export
 * @interface GetChargeItemResponseContent
 */
export interface GetChargeItemResponseContent {
    /**
     * 
     * @type {ChargeItem}
     * @memberof GetChargeItemResponseContent
     */
    item?: ChargeItem;
}


/**
 * Check if a given object implements the GetChargeItemResponseContent interface.
 */
export function instanceOfGetChargeItemResponseContent(value: object): boolean {
    let isInstance = true;
    return isInstance;
}

export function GetChargeItemResponseContentFromJSON(json: any): GetChargeItemResponseContent {
    return GetChargeItemResponseContentFromJSONTyped(json, false);
}

export function GetChargeItemResponseContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChargeItemResponseContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'item': !exists(json, 'item') ? undefined : ChargeItemFromJSON(json['item']),
    };
}

export function GetChargeItemResponseContentToJSON(value?: GetChargeItemResponseContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'item': ChargeItemToJSON(value.item),
    };
}

