/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */
import { exists, mapValues } from './model-utils';
import type { OccupancyStatus } from './OccupancyStatus';
import {
    OccupancyStatusFromJSON,
    OccupancyStatusFromJSONTyped,
    OccupancyStatusToJSON,
} from './OccupancyStatus';
import type { OccupancyType } from './OccupancyType';
import {
    OccupancyTypeFromJSON,
    OccupancyTypeFromJSONTyped,
    OccupancyTypeToJSON,
} from './OccupancyType';

/**
 * 
 * @export
 * @interface OccupancyItem
 */
export interface OccupancyItem {
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    bcId: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    bcName: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    blId: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    blName: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    buId: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    buName: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    ko1Id: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    ko1Key: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    lcId: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    lcName: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    ocBegin: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    ocCode: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    ocEnd: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    ocId: string;
    /**
     * 
     * @type {boolean}
     * @memberof OccupancyItem
     */
    ocIsClosed: boolean;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    ocName: string;
    /**
     * 
     * @type {OccupancyStatus}
     * @memberof OccupancyItem
     */
    ocStatus: OccupancyStatus;
    /**
     * 
     * @type {OccupancyType}
     * @memberof OccupancyItem
     */
    ocType: OccupancyType;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    scId: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    sgId: string;
    /**
     * 
     * @type {number}
     * @memberof OccupancyItem
     */
    spArea: number;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    spId: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    spName: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    suId: string;
    /**
     * 
     * @type {string}
     * @memberof OccupancyItem
     */
    suName: string;
}


/**
 * Check if a given object implements the OccupancyItem interface.
 */
export function instanceOfOccupancyItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "bcId" in value;
    isInstance = isInstance && "bcName" in value;
    isInstance = isInstance && "blId" in value;
    isInstance = isInstance && "blName" in value;
    isInstance = isInstance && "buId" in value;
    isInstance = isInstance && "buName" in value;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "ko1Id" in value;
    isInstance = isInstance && "ko1Key" in value;
    isInstance = isInstance && "lcId" in value;
    isInstance = isInstance && "lcName" in value;
    isInstance = isInstance && "ocBegin" in value;
    isInstance = isInstance && "ocCode" in value;
    isInstance = isInstance && "ocEnd" in value;
    isInstance = isInstance && "ocId" in value;
    isInstance = isInstance && "ocIsClosed" in value;
    isInstance = isInstance && "ocName" in value;
    isInstance = isInstance && "ocStatus" in value;
    isInstance = isInstance && "ocType" in value;
    isInstance = isInstance && "scId" in value;
    isInstance = isInstance && "sgId" in value;
    isInstance = isInstance && "spArea" in value;
    isInstance = isInstance && "spId" in value;
    isInstance = isInstance && "spName" in value;
    isInstance = isInstance && "suId" in value;
    isInstance = isInstance && "suName" in value;
    return isInstance;
}

export function OccupancyItemFromJSON(json: any): OccupancyItem {
    return OccupancyItemFromJSONTyped(json, false);
}

export function OccupancyItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OccupancyItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'key': json['key'],
        'id': json['id'],
        'bcId': json['bcId'],
        'bcName': json['bcName'],
        'blId': json['blId'],
        'blName': json['blName'],
        'buId': json['buId'],
        'buName': json['buName'],
        'entity': json['entity'],
        'ko1Id': json['ko1Id'],
        'ko1Key': json['ko1Key'],
        'lcId': json['lcId'],
        'lcName': json['lcName'],
        'ocBegin': json['ocBegin'],
        'ocCode': json['ocCode'],
        'ocEnd': json['ocEnd'],
        'ocId': json['ocId'],
        'ocIsClosed': json['ocIsClosed'],
        'ocName': json['ocName'],
        'ocStatus': OccupancyStatusFromJSON(json['ocStatus']),
        'ocType': OccupancyTypeFromJSON(json['ocType']),
        'scId': json['scId'],
        'sgId': json['sgId'],
        'spArea': json['spArea'],
        'spId': json['spId'],
        'spName': json['spName'],
        'suId': json['suId'],
        'suName': json['suName'],
    };
}

export function OccupancyItemToJSON(value?: OccupancyItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'key': value.key,
        'id': value.id,
        'bcId': value.bcId,
        'bcName': value.bcName,
        'blId': value.blId,
        'blName': value.blName,
        'buId': value.buId,
        'buName': value.buName,
        'entity': value.entity,
        'ko1Id': value.ko1Id,
        'ko1Key': value.ko1Key,
        'lcId': value.lcId,
        'lcName': value.lcName,
        'ocBegin': value.ocBegin,
        'ocCode': value.ocCode,
        'ocEnd': value.ocEnd,
        'ocId': value.ocId,
        'ocIsClosed': value.ocIsClosed,
        'ocName': value.ocName,
        'ocStatus': OccupancyStatusToJSON(value.ocStatus),
        'ocType': OccupancyTypeToJSON(value.ocType),
        'scId': value.scId,
        'sgId': value.sgId,
        'spArea': value.spArea,
        'spId': value.spId,
        'spName': value.spName,
        'suId': value.suId,
        'suName': value.suName,
    };
}

