import { ColDef, ColGroupDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import { useNavigate } from "react-router";
import {
  OccupancyItem,
  useGetOccupancyItems,
} from "xoura-api-typescript-react-query-hooks";
import { xouraTheme } from "../../layouts/Themes";

const RelatedOccupancyGrid = ({ occupancy, filter }) => {
  const gridRef = useRef<AgGridReact<OccupancyItem>>(null);
  const nav = useNavigate();
  const getOccupancyItems = useGetOccupancyItems(
    {
      currentOccupancyId: occupancy.id,
      legalContactId: occupancy.lcId,
    },
    { enabled: false },
  );

  const [defaultColDef] = React.useState({
    sortable: true,
    resizable: true,
    filter: true,
  });

  const [columnDefs] = React.useState<
    (ColDef<OccupancyItem, any> | ColGroupDef<OccupancyItem>)[]
  >([
    {
      headerName: "Name",
      field: "ocName",
      filter: "agTextColumnFilter",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      enableRowGroup: true,
    },
    {
      headerName: "Building",
      field: "buName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      rowGroup: false,
    },
    {
      headerName: "Level",
      field: "blName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      rowGroup: false,
    },
    {
      headerName: "Space",
      field: "spName",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      rowGroup: false,
    },
    {
      headerName: "Contacts",
      children: [
        {
          headerName: "Billing",
          field: "bcName",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          hide: true,
        },
        {
          headerName: "Legal",
          field: "lcName",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
        },
      ],
    },
    {
      headerName: "Occupancy",
      children: [
        {
          headerName: "Begin",
          field: "ocBegin",
          type: "rightAligned",
        },
        {
          headerName: "End",
          field: "ocEnd",
          type: "rightAligned",
        },
      ],
    },
    {
      headerName: "Status",
      field: "ocStatus",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
    {
      headerName: "Type",
      field: "ocType",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {};
  }, []);

  const [rowData, setRowData] = useState<OccupancyItem[]>();

  useEffect(() => {
    getOccupancyItems
      .refetch()
      .then((response) => setRowData(response.data?.items))
      .catch((err) => console.error(err));
  }, [occupancy]);

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (selectedRows?.length === 1) {
      nav("/occupancy/" + selectedRows[0].id, {
        state: { id: selectedRows[0].id },
      });
    }
  };

  const sideBar = useMemo(() => {
    return {
      defaultToolPanel: "",
      hiddenByDefault: false,
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
    };
  }, []);

  const onFirstDataRendered = useCallback(() => {
    gridRef.current?.api.applyColumnState({
      state: [
        { colId: "ocName", sort: "asc", sortIndex: 0 },
        { colId: "ocBegin", sort: "desc", sortIndex: 1 },
      ],
    });
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <>
      <AgGridReact<OccupancyItem>
        animateRows={true}
        autoGroupColumnDef={autoGroupColumnDef}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        groupDisplayType={"singleColumn"}
        includeHiddenColumnsInQuickFilter={true}
        onFirstDataRendered={onFirstDataRendered}
        onSelectionChanged={onSelectionChanged}
        quickFilterText={filter}
        ref={gridRef}
        rowData={rowData}
        rowGroupPanelShow={"always"}
        rowSelection={"single"}
        sideBar={sideBar}
        suppressCellFocus={true}
        theme={xouraTheme}
      ></AgGridReact>
    </>
  );
};

export default RelatedOccupancyGrid;
